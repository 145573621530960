@media screen and (max-width: 1250px) {
  div.surrogate-form-container {
    flex-direction: column;
    align-items: center;
  }
  div.surrogate-form-left,
  div.surrogate-form-right {
    width: 800px;
  }
  div.surrogate-form-right {
    margin-top: 50px;
  }

  div.select-container {
    max-width: unset;
    width: 740px;
  }

  div.select-options,
  span.surrogate-form-about {
    width: 740px;
  }
  div.select-container-half {
    width: 350px;
  }
  div.select-options-half {
    width: 350px;
  }
}
@media screen and (max-width: 850px) {
  div.surrogate-form-left,
  div.surrogate-form-right {
    width: 100%;
  }
  div.application-select-row {
    flex-direction: column;
    align-items: flex-start;
  }
  div.select-container-half,
  div.select-container,
  div.select-options-half {
    width: 80vw;
  }
}
@media screen and (max-width: 750px) {
  div.surrogate-form-left,
  div.surrogate-form-right {
    padding: 20px;
  }
}
@media screen and (max-width: 600px) {
  div.surrogate-form-left,
  div.surrogate-form-right {
    padding: 20px;
    align-items: center;
  }
  div.select-container-half,
  div.select-container,
  div.select-options-half {
    width: 70vw;
  }
  span.surrogate-form-about {
    width: 70vw;
  }
}
