@import url("./fonts.css");
@import url("./colors.css");
@import url("./auth.css");
@import url("./default-input.css");
@import url("./top-nav.css");
@import url("./side-nav.css");

@import url("./modal.css");
@import url("./megaloader.css");
@import url("./youtube-embed.css");

@import url("./dashboard/home.css");
@import url("./dashboard/account-management.css");
@import url("./dashboard/footer.css");
@import url("./dashboard/application.css");
@import url("./dashboard/reports.css");
@import url("./dashboard/messages.css");
@import url("./dashboard/custom-select.css");
@import url("./dashboard.css");

@import url("./small/SmallAuth.css");
@import url("./small/SmallHome.css");
@import url("./small/SmallDashboard.css");
@import url("./small/SmallTopNav.css");
@import url("./small/SmallApplication.css");
@import url("./small/SmallMessages.css");
@import url("./small/SmallModal.css");
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  outline: none !important;
}
.flex-row {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.width-100 {
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

.purple-btn-default {
  background-color: var(--purple-secondary);
  padding: 8px 30px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid transparent;
}
.none {
  display: none;
}
.css-nvf14r-ToastContainer {
  z-index: 9999999 !important;
  font-family: "poppins";
}
.filter-container {
  border: 1px solid var(--gray-tertiary);
  padding: 20px;
  border-radius: 10px;
  margin: 30px 0;
}
.filter-input {
  width: 200px;
  margin-right: 30px !important;
}
.filter-input:last-child {
  margin: 0px;
}
