.surrogate-content {
  margin-top: 150px;
}
.surrogate-report-modal {
  height: 900px;
  width: 1000px;
  max-width: 95vw;
  max-height: 90vh;
  box-sizing: border-box;
  padding: 30px;
  overflow: auto;
}
.full-surrogate-report-body {
  text-align: justify;
  line-height: 25px;
  max-height: 60vh;
  overflow-y: auto;
}
.surrogate-report-modal-footer {
  justify-content: flex-end;
  margin-top: 20px;
}
.close-surrogate-report {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  background-color: #000;
  color: #fff;
  width: 130px;
  height: 35px;
  align-items: center;
  justify-content: center;
}
