.dashboard-component {
  padding-left: 30px;
  width: 100%;
  box-sizing: border-box;
}
.recent-table-container {
  padding: 20px 40px;
  border-radius: 20px;
  margin: 15px 0;
}
.recent-table-container-small {
  padding: 30px 10px;
  border-radius: 20px;
  margin: 15px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  width: 49%;
}
.table-purple {
  background-color: var(--purple-tertiary);
}
.table-white {
  background-color: white;
}
.recent-table-row {
  color: rgba(12, 12, 12, 0.7);
  transition-duration: 0.12s;
}

.table-small-row {
  font-size: 13px !important;
}

.table-purple-row:hover {
  /* background-color: #cec0e0; */
}
.table-white-row:hover {
  /* background-color: rgb(223, 223, 223); */
}
.recent-table-actions {
  justify-content: space-between;
}
.recent-table-action {
  width: 30%;
  overflow: visible;
  cursor: pointer;
}
.recent-table-action:hover {
  color: var(--blue-default);
}
.table-small-actions {
  justify-content: space-between;
}
.table-small-action {
  width: fit-content;
}
.table-small-action:last-child {
  margin-left: 20px;
}
