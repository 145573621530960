.auth-bg {
  background-color: var(--pink-default);
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.auth-container {
  align-items: center;
  justify-content: center;
}
.auth-form {
  width: 500px;
  padding: 20px;
  justify-content: space-between;
}
.login-form {
  height: 600px;
}
.reset-form {
  height: 500px;
}
.auth-image {
  width: 250px;
}
.auth-title {
  text-transform: uppercase;
  font-weight: 600 !important;
  font-family: "Cinzel", serif !important;
}
.auth-btn {
  width: 100%;
  height: 43px;
  border-radius: 5px;
  background-color: var(--blue-default);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid var(--blue-default);
  outline: none;
  cursor: pointer;
  font-family: "Cinzel", serif;
}
.auth-btn-half {
  width: 48% !important;
}
.auth-btn-request-otp {
  color: var(--blue-default);
  background-color: transparent;
}
