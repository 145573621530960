@media screen and (max-width: 1200px) {
  div.small-tables-row {
    flex-direction: column;
  }

  div.recent-table-container-small {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  div.dashboard-component {
    margin-top: 50px;
  }
}
@media screen and (max-width: 480px) {
  div.dashboard-component {
    padding: 0 10px;
  }
}
