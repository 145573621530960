@import url("./modal/surrogate-reports.css");
@import url("./modal/confirmation.css");
.default-modal-container {
  align-items: center;
  justify-content: center;
  height: 100%;
  outline: none;
}
.default-modal-content {
  background-color: #fff;
  border-radius: 30px 5px 5px 30px;
}
.modal-scrollbar {
  padding-right: 10px;
}
.modal-scrollbar::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.modal-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  height: 80px;
  border-radius: 5px;
}

/* Handle */
.modal-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.modal-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal-about {
  background-color: var(--purple-secondary);
  padding: 7px;
  border-radius: 6px;
  text-align: center;
  width: 60%;
}
.modal-form-container {
  margin: 30px 0;
  justify-content: space-between;
}
.modal-form {
  width: 60%;
  border: 1px solid var(--white-primary);
  border-radius: 10px;
  padding: 30px;
}
.modal-input-row {
  margin: 12px 0;
}
.modal-input-half {
  width: 47%;
}
.modal-input-full {
  width: 100%;
}
.modal-form-right {
  justify-content: space-between;
  width: 30%;
  align-items: center;
}
.modal-form-image-container {
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  border: 2px solid #bdbdbd;
  border-radius: 15px;
  width: 240px;
  height: 240px;
}
.modal-form-image-container-small {
  width: 220px;
  height: 180px;
}
.modal-form-image {
  height: 150px;
  max-width: 200px;
  object-fit: contain;
}
.modal-form-submit {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modal-image-hide {
  visibility: hidden;
  z-index: -999999;
  position: absolute;
  bottom: 0;
  opacity: 0;
}
.surrogate-form-btn {
  width: 220px !important;
  font-size: 14px !important;
}
.modal-form-file-container {
  width: 100%;
  margin-bottom: 20px;
}
.modal-form-file {
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--gray-default);
  border-radius: 15px;
  height: 45px;
  padding: 5px 7px 5px 10px;
  margin: 5px 0;
}
.modal-form-file-btn {
  height: 100%;
  background-color: var(--purple-secondary);
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid transparent;
}
.modal-form-file-about {
  color: rgba(189, 184, 184, 1);
}
.full-border-modal {
  border-radius: 30px;
}
.modal-form-create-pairing-container {
}
.modal-form-create-pairing {
  width: 100%;
  max-width: 600px;
}
.modal-form-create-pairing-right {
  width: 100%;
  max-width: 600px;
}
.modal-about-create-pairing {
  padding: 15px 30px !important;
}

.pairing-person-tag {
  border: 1px solid #000000;
  border-radius: 15px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin-top: 10px;
}
.pairing-modal-submit {
  width: 240px;
}
