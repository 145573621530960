@media screen and (max-width: 1250px) {
  div.dashboard-block {
    padding: 27px;
  }
}
@media screen and (max-width: 1000px) {
  div.dashboard-block-footer {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
@media screen and (max-width: 850px) {
  div.dashboard-blocks {
    flex-direction: column;
    align-items: center;
  }
  div.dashboard-block {
    width: 100%;
    margin: 10px 0;
  }
}
@media screen and (max-width: 800px) {
  div.recent-blocks {
    flex-direction: column;
    align-items: center;
  }
  div.recent-block {
    width: 100%;
    margin: 10px 0;
  }
}
