@media screen and (max-width: 850px) {
  div.filter-container {
    flex-direction: column;
    align-items: flex-start;
  }
  div.filter-inputs {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 700px) {
  div.reports-top {
    /* margin-top: 70px; */
  }
}
@media screen and (max-width: 550px) {
  div.filter-inputs {
    flex-direction: column;
  }
  div.filter-input:first-child {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 500px) {
  span.surrogate-reports-head {
    display: none;
  }
}
