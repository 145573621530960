@media screen and (max-width: 700px) {
	span.toggle-topnav {
		display: flex;
		/* position: absolute; */
		right: 20px;
		top: 25px;
		cursor: pointer;
		z-index: 99999;
		position: fixed;
	}

	div.top-nav-container {
		right: 0px;
		padding: 30px 40px;
		background-color: white;

		/* added */
		position: fixed;
		z-index: 999;
		width: 100%;
		box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
		height: 6rem;
	}

	div.top-nav-right {
		/* background-color: white; */
		/* position: fixed; */
		width: 320px;
		/* right: 0px; */
		/* top: 80px; */
		padding: 0px 10px;
		box-sizing: border-box;
		justify-content: space-between;
		overflow: clip;
		height: 100px;
		align-items: center;

		position: fixed;
		top: 0px;
		/* right: 1rem; */
		background: transparent;
	}
}