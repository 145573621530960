.footer-row {
  width: 100%;
  padding: 30px 0;
  align-items: center;
  justify-content: flex-end;
}
.footer-link {
  text-decoration: underline !important;
  margin: 0 20px;
  color: rgba(0, 0, 0, 1);
}
.footer-link:last-child {
  margin-right: 0;
}
@media screen and (max-width: 550px) {
  a.footer-link {
    margin: 0 12px;
  }
  a.footer-link:last-child {
    margin-right: 0;
  }
}
