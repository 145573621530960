.messages-page-container {
  width: 100%;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  margin-top: 30px;
}
.messages-sidebar {
  width: 30%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 4px 5px 2px #cecce4;
}
.messages-search-container {
  width: 100%;
  background-color: #fff;
  border-radius: 25px;
  outline: none;
  height: 50px;
  padding: 0px 10px;
  margin-bottom: 30px;
}
.messages-search-icon {
  color: var(--gray-secondary);
  padding: 0 10px;
}
.messages-search {
  background-color: #fff;
  outline: none;
  border: none;
  height: 85%;
  width: 90%;
  box-sizing: border-box;
}
.messages-search::placeholder {
  color: var(--gray-secondary);
}

.sidebar-section {
  /* box-shadow: 0px 4px 5px 2px #cecce4; */
  border-radius: 25px;
  padding: 20px 10px;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.sidebar-item {
  border-bottom: 1px solid var(--gray-primary);
  padding: 10px 0;
  cursor: pointer;
  transition-duration: 0.12s;
  height: 70px;
}
.sidebar-section .sidebar-item:last-child {
  border-bottom: none;
}
.sidebar-item-image {
  height: 30px;
  width: 30px;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 10px;
}
.sidebar-item-details {
  max-width: 180px;
}
.sidebar-item-badge {
  background-color: var(--orange-primary);
  border-radius: 50%;
  color: #fff;
  width: 26px;
  height: 26px;

  margin-left: 4px;
  display: block;
}
.sidebar-item:hover {
  opacity: 0.7;
}

.chat-section {
  width: 65%;
  background: #ffffff;
  box-shadow: 0px 4px 5px 2px #cecce4;
  border-radius: 25px;
  padding: 20px;
  justify-content: space-between;
  box-sizing: border-box;
}

.chat-header {
  border-bottom: 1px solid rgba(180, 171, 171, 0.66);
  padding-bottom: 15px;
}
.chat-header-left {
}
.chat-section-avatar {
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 7px;
}
.chat-menu-btn {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: transparent;
  transition-duration: 0.12s;
}

.chat-menu-btn:hover {
  background-color: #cecce4;
}

.chat-messages {
  margin-top: 20px;
  max-height: 500px;
  overflow-y: auto;
}
.chat-messages-bottom {
}
.send-message-container {
  background-color: var(--purple-secondary);
  border-radius: 25px;
  height: 40px;
  padding-right: 10px;
  width: -webkit-fill-available;
}
.send-message-input {
  background-color: var(--purple-secondary);
  outline: none;
  border: none;
  height: 100%;
  width: 95%;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding-left: 20px;
  box-sizing: border-box;
}
.send-message-btn {
  width: 180px;
  margin-left: 20px;
  height: 40px;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--purple-default);
  transition-duration: 0.12s;
}
.send-message-btn:hover {
  background: #6e00ff;
}

.received-message-container {
  justify-content: flex-start;
  align-items: flex-end;
}
.sent-message-container {
  justify-content: flex-end;
  align-items: flex-end;
}
.message-badge {
  width: 12px;
  height: 12px;
  margin-bottom: -5px;
  border-radius: 50%;
}
.received-message-badge {
  background: #e7e7e7;
}
.sent-message-badge {
  background-color: var(--purple-another);
}
.message-text {
  padding: 10px 20px;
  border-radius: 13px;
}
.received-message-text {
  background: #e7e7e7;
  color: #303030;
}

.sent-message-text {
  background-color: var(--purple-another);
}
.message-time {
  margin-top: 7px;
}
.sent-message-time {
  margin-right: 15px;
}
.received-message-time {
  margin-left: 15px;
}
